<template>
  <v-app>
    <div v-if="$store.state.loading" class="loading-container">
      <v-progress-circular
          :size="70"
          :width="10"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    <router-view v-else></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    //
  }),
  created() {
    document.title = 'Service'
  }
};
</script>

<style scoped>
.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>

<style>

.box {
  border: red solid 4px;
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
</style>