<template>
  <div class="container d-flex align-center justify-center">
    <v-card
        width="500"
        height="350"
        outlined
        class="win pa-4"
    >
      <v-card-title>登陆</v-card-title>
      <v-card-text class="">
        <v-text-field label="账号" v-model="username" outlined
                      v-on:keyup.enter="login"
                      :error="error.length>0"
        ></v-text-field>
        <v-text-field label="密码" v-model="password" outlined
                      :type="showPass ? 'text' : 'password'"
                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPass = !showPass"
                      v-on:keyup.enter="login"
                      :error-messages="error"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn style="width: 100%" color="primary"
               @click="login"
        >登陆
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import cloudbase from "@/plugins/cloudbase";

export default {
  name: "index",
  data() {
    return {
      username: '',
      password: '',
      showPass: false,
      error: ''
    }
  },
  methods: {
    login() {
      if (this.username.length === 0 || this.password.length === 0) {
        this.error = '请输入用户名密码'
        return
      }
      cloudbase.auth()
          .signInWithUsernameAndPassword(this.username, this.password)
          .then(s => {
            this.$store.commit('setUser',s.user)
            return this.$store.dispatch('setLoginUser', s.user.uid)
          })
          .then(() => {
            this.$router.push('/')
          })
          .catch(error => {
            console.log(error)
            this.error = '账号或密码错误'
          })
    },
    init(userId) {
      console.log(userId)
      return cloudbase.database().collection('user_role')
          .where({
            user_uuid: userId
          })
          .get()
    }

  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
}

.win {
  box-sizing: border-box;
}

</style>