import Vue from 'vue'
import VueRouter from 'vue-router'
import {routeList} from "@/router/menus";
import mainContainer from "@/components/layout/mainContainer";
import login from "@/views/login";
import store from "@/store";
import app from "@/plugins/cloudbase";

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        name: '首页',
        component: mainContainer,
        children: [
            {
                path: '/',
                name: '首页',
                icon: 'home-minus-outline',
                component: () => import('../views/dashboard')
            },
            ...routeList
        ]
    },
    {
        title: 'Login Page',
        path: '/login',
        name: 'login',
        component: login
    },
]


const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    console.log('beforeEach', {to, from, role: store.state.role})
    if (to.path === '/login') return next()
    if (store.state.role) {
        console.log('roles', store.state.role,)
        checkPermission(to, next)
    } else {
        const user = app.auth().currentUser
        if (user) {
            store.commit('setLoading', true)
            let task = []

            store.commit('setUser',user)
            task.push(store.dispatch('setLoginUser', user.uid))
            task.push(store.dispatch('getProjectSettings'))

            Promise.all(task).then(() => {
                store.commit('setLoading', false)
                console.log('beforeEach2', {to, from, role: store.state.role})
                checkPermission(to, next)
            })
        } else {
            next('/login')
        }

    }
})

const checkPermission = (to, next) => {
    if (to.path === '/') return next()
    if (!to.meta?.auth) return next()
    if (store.state.role.some(s => {
        return s.path === to.path
    })) {
        return next()
    }
    return next('/')

}

const DEFAULT_TITLE = 'Service';
router.afterEach((to) => {

    Vue.nextTick(() => {
        document.title = to.name || DEFAULT_TITLE;
    });
});


export default router
