<template>
  <div>
    <top/>
    <navigation/>
    <v-main>
      <v-container fluid>
        <breadcrumbsNav/>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>

</template>

<script>
import top from "@/components/layout/Header";
import navigation from "@/components/layout/Navigation";
import breadcrumbsNav from "@/components/layout/BreadcrumbsNav";


export default {
  name: 'mainContainer',
  components: {
    navigation, top, breadcrumbsNav
  },
  data: () => ({}),
  created() {
    console.log(this.$store.state.role)
    // this.$store.commit('setLoading', true)
    // let task = []
    // task.push(this.$store.dispatch('getProjectSettings'))
    //
    // Promise.all(task).then(() => {
    //   this.$store.commit('setLoading', false)
    // })

  }
};
</script>

<style scoped>

</style>