const service = [{
    path: '/service/order/',
    name: '工单管理',
    icon: 'progress-wrench',
    meta:{
        auth:true
    },
    component: () => import('../views/service/order/index'),
    children: [
        {
            path: '',
            name: '工单列表',
            component: () => import('../views/service/order/list'),
            meta:{
                auth:true
            }
        },
        {
            path: ':orderId',
            name: '工单详情',
            component: () => import('../views/service/order/detail'),
            props: true
        },
    ]
},]
const order = [{
    path: '/contract/list',
    name: '定单列表',
    icon: 'note-check-outline',
    component: () => import('../views/contract/list'),
    meta:{
        auth:true
    }
},]
const report = [
    {
        path: '/dashboard',
        name: '看板',
        icon: 'chart-bell-curve-cumulative',
        component: () => import('../views/dashboard'),
        meta:{
            auth:true
        }
    },
]
const product = [
    {
        path: '/product/list',
        name: '产品',
        icon: 'package-variant-closed',
        component: () => import('../views/product/list'),
        meta:{
            auth:true
        }
    },
]
const personalSettings = [
    {
        path: '/person',
        name: '个人设置',
        icon: 'card-bulleted-outline',
        component: () => import('../views/person'),
        meta:{
            auth:false
        }
    },
]
const systemSettings = [
    {
        path: '/system/settings',
        name: '系统管理',
        icon: 'cog-outline',
        component: () => import('../views/systemSetting/index'),
        meta:{
            auth:true
        }
    },
]
const organizationSettings = [
    {
        path: '/userManage',
        name: '用户管理',
        icon: 'account-plus-outline',
        component: () => import('../views/userManage'),
        meta:{
            auth:true
        }
    },
    {
        path: '/organization',
        name: '组织管理',
        icon: 'account-group-outline',
        component: () => import('../views/organization/index'),
        meta:{
            auth:true
        }
    },
    {
        path: '/roleManage',
        name: '角色管理',
        icon: 'cog-outline',
        component: () => import('../views/systemSetting/roleManage'),
        meta:{
            auth:true
        }
    },
]
const bizSettings = [

    {
        path: '/setting/projectSettings',
        name: '项目设置',
        icon: 'account-wrench-outline',
        component: () => import('../views/service/serviceSetting/projectSettings'),
        meta:{
            auth:true
        }
    },
]

const lab = [

    {
        path: '/lab/ocr',
        name: '文字识别',
        icon: 'ocr',
        component: () => import('../views/ocr'),
        meta:{
            auth:true
        }
    },
    {
        path: '/lab/workflow',
        name: '工作流',
        component: () => import('../components/workflow/index'),
        meta:{
            auth:true
        }
    },
    {
        path: '/lab/kanban',
        name: '看板test',
        component: () => import('../views/kanban/index'),
        meta:{
            auth:true
        }
    },
    {
        path: '/lab/component-dev',
        name: '组件调试',
        component: () => import('../views/lab/component-lab'),
        meta:{
            auth:true
        }
    },
]

const jdCar = [

    {
        path: '/jd-car/tire-progress',
        name: '轮胎险进度查询',
        icon: 'tire',
        component: () => import('../views/service/jd-car'),
        meta:{
            auth:true
        },
        props:{
            project:{
                name:'JD轮胎险'
            }
        }
    },
    {
        path: '/jd-car/tire-orders',
        name: '轮胎险订单查询',
        icon: 'tire',
        component: () => import('../views/service/jd-car/order-tires'),
        meta:{
            auth:true
        }
    },
]


const menus = [
    {
        items: report,
        name: '数据看板',
        icon: 'finance',
    },
    {
        items: product,
        name: '产品管理',
        icon: 'toolbox-outline'
    },

    {
        items: service,
        name: '服务',
        icon: 'face-agent'
    },
    {
        items: order,
        name: '订单',
        icon: 'bookmark-outline'
    },

    {
        items: personalSettings,
        name: '个人设置',
        icon: 'account-cog-outline'
    },
    {
        items: systemSettings,
        name: '系统设置',
        icon: 'cog-outline'
    },
    {
        items: bizSettings,
        name: '业务设置',
        icon: 'store-cog-outline'
    },
    {
        items: organizationSettings,
        name: '组织管理',
        icon: 'sitemap-outline'
    },
    {
        items: lab,
        name: '实验室',
        icon: 'test-tube'
    },
    {
        items: jdCar,
        name: 'JD车服务',
        icon: 'car'
    },


]

const routeList = menus.reduce((pre, curMenu) => {
    const pages = curMenu.items.map(s => {
        s.menuName = curMenu.name
        return s
    })
    return pre.concat(pages)
}, [])

const getMenus = ()=>{
    return JSON.parse(JSON.stringify(menus))
}

export default getMenus
export {routeList}