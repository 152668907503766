<template>
  <v-navigation-drawer permanent :mini-variant="$store.state.app['mini-variant']" app>
    <v-list>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Service-One
          </v-list-item-title>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>


    <v-list dense nav>
      <v-list-group v-for="group in authedMenu" :key="group.name"
                    :prepend-icon="'mdi-'+group.icon"
                    no-action
                    :value="group.active"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="group.name"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            v-for="child in group.items"
            :key="child.path"
            :to="child.path"
        >
          <v-list-item-icon class="mr-0">
            <v-icon v-text="'mdi-circle-small'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="child.name"></v-list-item-title>

        </v-list-item>
      </v-list-group>
    </v-list>
    <v-divider></v-divider>

  </v-navigation-drawer>
</template>
<script>
import getMenus, {routeList} from "@/router/menus";


export default {
  name: "Navigation",
  data() {
    return {
      group: 0,
      menus:getMenus()
    }
  },
  created() {
    this.setSelected()
  },
  methods: {
    setSelected() {
      let [page] = routeList.filter(s => s.path === this.$route.path)
      if (page) {
        let menu = this.menus.find(s => s.name === page.menuName)
        if (menu) {
          menu.active = true
        }
      }
    },
  },
  computed: {
    authedMenu() {
      return this.menus.reduce((pre, cur) => {
        cur.items = cur.items.filter(p => {
          if (p.meta?.auth) {
            return this.$store.state.role.some(auth => {
              return auth.path === p.path
            })
          }
          return true
        })
        if (cur.items.length > 0) {
          pre.push(cur)
        }
        return pre
      }, [])
    },
  },
}
</script>

<style scoped>

</style>