<template>
  <div>
    <v-breadcrumbs
        :items="currentRoute"
        divider="/"
    ></v-breadcrumbs>


  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    currentRoute() {
      let breadcrumbs = []
      let routes = this.$route.matched.map(s=>{
        return {
          text: s.name,
          to: s.path,
          exact:true,
          disabled: false,
        }
      })
      routes.pop()
      breadcrumbs.push(...routes)
      breadcrumbs.push({
        text: this.$route.name,
        to: this.$route.path,
        disabled: false,
      })
      return breadcrumbs
    }
  }
}
</script>

<style scoped>

</style>