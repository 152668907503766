import Vue from 'vue'
import Vuex from 'vuex'
import db from "@/plugins/db";
// import app from "@/plugins/cloudbase"
import router from "@/router";
import cloudbase from "@/plugins/cloudbase";


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        serviceSettings: {
            serviceOrderStatus: []
        },
        projectSettings: [],
        user: {
            // avatarUrl: "",
            // customUserId: "",
            // email: "",
            // gender: '',
            // hasPassword: true,
            // location: Object,
            // loginType: "USERNAME",
            // nickName: "ning",
            // openid: "",
            // phone: "",
            // qqMiniOpenId: "",
            // uid: "ce2a68a1a8794a098d9099beb94fde16",
            // unionId: "",
            // username: "wuning",
            // wxOpenId: "",
            // wxPublicId: "",
        },
        role: null,
        app: {
            'mini-variant': false
        }
    },
    getters: {
        getServiceSettings:(state)=>(projectName)=> {
            return state.projectSettings.find(s => {
                return s.name === projectName
            })
        },
        pagePermission(state) {
            const pagePermission = state.role.find(s => {
                return s.path === router.currentRoute.path
            })
            console.log('pagePermission',pagePermission)
            return pagePermission || {}

        }
    },
    mutations: {
        setUser(state,user){
            state.user = user
        },
        setLoading(state, value) {
            state.loading = value
        },
        setServiceSettings(state, serviceSetting) {
            state.serviceSettings = serviceSetting
        },
        setProjectSettings(state, projectSettings) {
            state.projectSettings = projectSettings
        },
        setRole(state, role) {
            state.role = role
        },
        switchNav(state) {
            state.app["mini-variant"] = !state.app["mini-variant"]
        }
    },
    actions: {
        getProjectSettings(context) {
            return db.collection('projectSettings')
                .get()
                .then(s => {
                    context.commit('setProjectSettings', s.data)
                })
        },
        getServiceSettings(context) {
            return db.collection('serviceSettings')
                .get()
                .then(s => {
                    context.commit('setServiceSettings', s.data[0])
                })
        },
        setLoginUser(context, userId) {
            return cloudbase.database()
                .collection('user_role')
                .where({
                    user_uuid: userId
                })
                .get()
                .then(({data}) => {
                    const role_user = data[0]
                    if (role_user) {
                        return cloudbase.database().collection('role')
                            .doc(role_user.role)
                            .get()
                            .then(({data}) => {
                                console.log('role:', data[0]?.permissions)
                                context.commit('setRole', data[0]?.permissions)
                            })
                    }
                })
        }
    },
    modules: {}
})
