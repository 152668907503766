<template>
  <v-app-bar
      app
      color="rgba(255, 255, 255, 0.2)"
      dark
      dense
      elevation="0"
      class="glass"
  >
    <div class="d-flex align-center ">
      <v-icon class="deep-purple--text" @click="$store.commit('switchNav')">mdi-menu</v-icon>
    </div>

    <v-spacer></v-spacer>

    <v-icon  class="deep-purple--text">mdi-bell-outline</v-icon>
    <v-menu
        rounded="lg"
        offset-y
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            rounded
            color="deep-purple accent-4"
            class="white--text ma-5"
            v-bind="attrs"
            v-on="on"
        >
          {{ $store.state.user.nickName }}
          <v-avatar size="28" class="ml-2 black" color="indigo">
            <img v-if="!avatarError" :src="$store.state.user.avatarUrl" @error="avatarError =true">
            <span v-else class="overline white--text" > {{ name }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item link dense to="/person">
          <v-list-item-icon>
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title >个人设置</v-list-item-title>
        </v-list-item>

        <v-list-item link dense  @click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-title >退出</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import app from '@/plugins/cloudbase'
export default {
  name: "Header",
  data: () => ({
    avatarError:false,
    btns: [
      ['Removed', '0'],
      ['Large', 'lg'],
      ['Custom', 'b-xl'],
    ],
    colors: 'deep-purple accent-4',
    items: ['login','settings'],
  }),
  methods:{
    signOut(){
      app.auth().signOut().then(()=>{
        this.$router.push('/login')
      })
    }
  },
  computed: {
    name() {
      const name = this.$store.state.user.nickName || ''
      return name.substring(name.length -2)
    },
  }
}
</script>

<style scoped>
.glass {
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

</style>